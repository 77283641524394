import { SelectDos } from "../plugins/select2";
import { getData, CSRF, getJson, b64Uri, setSelect2Ajax } from "../helpers";
// import ucwords from "ucwords";

export const dataBank = { companies: [], notIn: [] };

/**
 * Implementar el plugin Select2 para las empresas
 *
 */
export class SelectBank extends HTMLSelectElement {
    async connectedCallback() {
        let configSelect = {
            ajax: {
                url: getData("banksUrlSelect"),
                type: "post",
                dataType: "json",
                cache: true,
                data: function (param) {
                    return {
                        _token: CSRF,
                        search: param.term,
                        companies: dataBank.companies,
                        notIn: dataBank.notIn,
                        // limit: 10,
                    };
                },
                processResults: function (response) {
                    // response.forEach((element) => {
                    //     element.id = element.alpha2;
                    // });
                    return {
                        results: response,
                    };
                },
            },
            // templateResult: (data) => ucwords(data.text),
            // templateSelection: (data) => {
            //     if (!data.id) {
            //         return data.text;
            //     }
            //     return `${data.alpha2}`;
            // },
        };

        const SD = new SelectDos(configSelect);
        const S = await SD.selectDos(this);

        S[0]?.addEventListener("aj:select2", (e) => {
            const Data = e.detail.data;

            if (S[0].hasAttribute("multiple")) {
                dataBank.notIn.push(Data.id);
            } else {
                dataBank.notIn = [Data.id];
            }
        });

        S[0]?.addEventListener("aj:unselecting", (e) => {
            const Data = e.detail.data;
            dataBank.notIn = dataBank.notIn.filter((item) => item != Data.id);
        });

        // Código Carlos
        if (getData(this, "data-default")) {
            let def = getJson(b64Uri(getData(this, "data-default"), "decode"));
            if (def?.id) {
                setSelect2Ajax(
                    this,
                    def.id,
                    def.account,
                    def
                );
            }
        }
        // Fin Código Carlos
    }
}

window.customElements.define("select-bank", SelectBank, {
    extends: "select",
});
